import Joi from 'joi';
import { ErrorCodes } from '../constants/errorCodes';

export const CompanyConfigSchema = Joi.object({
  PK: Joi.string(),
  SK: Joi.string(),
  TTL: Joi.string(),
  features: Joi.object({
    cognito: Joi.boolean().required().error(new Error(ErrorCodes.FEATURES_UNDEFINED)),
    OTP: Joi.boolean().required().error(new Error(ErrorCodes.FEATURES_UNDEFINED)),
    urlTokenAuth: Joi.boolean().required().error(new Error(ErrorCodes.FEATURES_UNDEFINED)),
    urlParamAuth: Joi.boolean().required().error(new Error(ErrorCodes.FEATURES_UNDEFINED)),
    campaigns: Joi.boolean().required().error(new Error(ErrorCodes.FEATURES_UNDEFINED))
  }).required().error(new Error(ErrorCodes.FEATURES_UNDEFINED)),
  properties: Joi.object({
    companyName: Joi.string().required().error(new Error(ErrorCodes.PROPERTIES_UNDEFINED)),
    directline: Joi.object({
      key: Joi.string().required().error(new Error(ErrorCodes.PROPERTIES_UNDEFINED))
    }).required().error(new Error(ErrorCodes.PROPERTIES_UNDEFINED)),
    baseUrls: Joi.object({
      campaign: Joi.string().required().error(new Error(ErrorCodes.PROPERTIES_UNDEFINED)),
      directline: Joi.string().required().error(new Error(ErrorCodes.PROPERTIES_UNDEFINED))
    }),
    sessionTimeout: Joi.object({
      sessionInactivityTimeout: Joi.string().error(new Error(ErrorCodes.PROPERTIES_UNDEFINED)),
      sessionExpiryWarningTimeout: Joi.string().error(new Error(ErrorCodes.PROPERTIES_UNDEFINED)),
    }).required().error(new Error(ErrorCodes.PROPERTIES_UNDEFINED)),
    appName: Joi.string().required().error(new Error(ErrorCodes.PROPERTIES_UNDEFINED)),
    channelId: Joi.string().required().error(new Error(ErrorCodes.PROPERTIES_UNDEFINED)),
    id: Joi.string().required().error(new Error(ErrorCodes.PROPERTIES_UNDEFINED)),
    startUpMessage: Joi.string().required().error(new Error(ErrorCodes.PROPERTIES_UNDEFINED)),
  }).required().error(new Error(ErrorCodes.PROPERTIES_UNDEFINED)),
  styles: Joi.object({
    botAvatarInitials: Joi.string().required().error(new Error(ErrorCodes.STYLES_UNDEFINED)),
    chatBackground: Joi.string().required().error(new Error(ErrorCodes.STYLES_UNDEFINED)),
    header: Joi.object({
      background: Joi.string().required().error(new Error(ErrorCodes.STYLES_UNDEFINED)),
      cardBackground: Joi.string().required().error(new Error(ErrorCodes.STYLES_UNDEFINED)),
      cardTextColor: Joi.string().required().error(new Error(ErrorCodes.STYLES_UNDEFINED)),
    }).required().error(new Error(ErrorCodes.STYLES_UNDEFINED)),
    login: Joi.object({
      background: Joi.string().required().error(new Error(ErrorCodes.STYLES_UNDEFINED)),
      screen: Joi.string().required().error(new Error(ErrorCodes.STYLES_UNDEFINED)),
      shape1: Joi.string().required().error(new Error(ErrorCodes.STYLES_UNDEFINED)),
      shape2: Joi.string().required().error(new Error(ErrorCodes.STYLES_UNDEFINED)),
      shape3: Joi.string().required().error(new Error(ErrorCodes.STYLES_UNDEFINED)),
      shape4: Joi.string().required().error(new Error(ErrorCodes.STYLES_UNDEFINED)),
    }).required().error(new Error(ErrorCodes.STYLES_UNDEFINED)),
    mainSubColor: Joi.string().required().error(new Error(ErrorCodes.STYLES_UNDEFINED)),
    mainTertiaryColor: Joi.string().required().error(new Error(ErrorCodes.STYLES_UNDEFINED)),
  }).required().error(new Error(ErrorCodes.STYLES_UNDEFINED)),
  cognito: Joi.object({
    APP_CLIENT_ID: Joi.string().error(new Error(ErrorCodes.COGNITO_UNDEFINED)),
    REGION: Joi.string().error(new Error(ErrorCodes.COGNITO_UNDEFINED)),
    USER_POOL_ID: Joi.string().error(new Error(ErrorCodes.COGNITO_UNDEFINED))
  })

});